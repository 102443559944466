import { Carousel } from "@/UI/Carousel";
import { Modal } from "@/UI/Modal";
import { DownloadIcon } from "@/assets";
import { downloadImage } from "@/utils/helpers/downloadImage";
import { Dispatch, FC, SetStateAction, useState } from "react";

interface AssetImageModalProps {
  imageModal: {
    open: boolean;
    initialIndex?: number | undefined;
  };
  setImageModal: Dispatch<
    SetStateAction<{
      open: boolean;
      initialIndex?: number | undefined;
    }>
  >;

  images: { url: string; id: string; contentType: string; name: string }[];
}

export const AssetImageModal: FC<AssetImageModalProps> = ({
  imageModal,
  setImageModal,
  images,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const onSlideChange = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <Modal
      hideButtons
      innerContainerClasses="!p-0 !bg-[#2E2E2E] !rounded-2xl "
      open={imageModal.open}
      onCancel={() => setImageModal({ open: false })}
    >
      <button
        onClick={() => setImageModal({ open: false })}
        className="absolute -right-20 h-10 w-10 rounded-full bg-[#e3e3e3] text-gray-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.3}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </button>
      <button
        onClick={() =>
          downloadImage({
            extension: images[currentIndex].contentType ?? "png",
            fileName: images[currentIndex].name.split(".")[0],
            url: images[currentIndex].url,
          })
        }
        className="absolute -right-20 top-20 h-10 w-10 rounded-full bg-black text-black "
      >
        <DownloadIcon />
      </button>

      <Carousel
        slides={images}
        initialIndex={imageModal.initialIndex}
        onSlideChange={onSlideChange}
      />
    </Modal>
  );
};
