import { APIProvider } from "@vis.gl/react-google-maps";
import { FC, ReactNode } from "react";
import { useGoogleMapsAPIKey } from "@/lib/react-query/queryHooks/useGoogleMapsAPIKey";

type MapWrapperProps = {
  children: ReactNode;
};

const MapWrapper: FC<MapWrapperProps> = ({ children }) => {
  const { data: apiKey, isError } = useGoogleMapsAPIKey();

  if (!apiKey || isError) return <>{children}</>;

  return (
    <APIProvider apiKey={apiKey} libraries={["places", "geocoding"]}>
      {children}
    </APIProvider>
  );
};

export { MapWrapper };
