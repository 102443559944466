/* eslint-disable @typescript-eslint/no-explicit-any */

import { Control, Controller } from "react-hook-form";
import { FC, InputHTMLAttributes } from "react";
import { GoogleAutocomplete } from "./GoogleAutocomplete";

interface ControlledGoogleAutocompleteProps
  extends InputHTMLAttributes<HTMLInputElement> {
  control: Control<any, object>;
  customChange?: (value: any) => void;
  customValue?: any;
  label?: string;
  name: string;
  onClickEndAdorment?: () => void;
  required?: boolean;
}

export const ControlledGoogleAutocomplete: FC<
  ControlledGoogleAutocompleteProps
> = ({
  control,
  customChange,
  customValue,
  label,
  name,
  onClickEndAdorment,
  required,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <GoogleAutocomplete
          error={error}
          label={label}
          onChange={customChange ?? onChange}
          onClickEndAdorment={onClickEndAdorment}
          value={customValue ?? value}
        />
      )}
    />
  );
};
