import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { graphql } from "@/lib/graphql";
import { EditFormTemplateMutationVariables } from "@/lib/graphql/graphql";
import { useMutation } from "@tanstack/react-query";

const EditFormTemplate = graphql(`
  mutation EditFormTemplate(
    $updateFormTemplateId: ID!
    $input: GqlUpdateFormTemplateInput!
  ) {
    updateFormTemplate(id: $updateFormTemplateId, input: $input) {
      message
      success
      code
    }
  }
`);

export const useEditFormGql = () => {
  const { graphqlRequest } = useAuthContext();
  return useMutation({
    mutationFn: async (
      editFormTemplateMutationVariables: EditFormTemplateMutationVariables
    ) => graphqlRequest(EditFormTemplate, editFormTemplateMutationVariables),
  });
};
