export const parseLocationValue = (value: string) => {
  if (!value) return;

  const splittedValue = (value as string).split(",");

  if (
    splittedValue.length >= 3 &&
    Number(splittedValue[0]) &&
    Number(splittedValue[1])
  ) {
    const lat = Number(splittedValue.splice(0, 1)[0]);
    const lng = Number(splittedValue.splice(0, 1)[0]);
    const name = splittedValue.join(",");

    return {
      lat,
      lng,
      name,
    };
  }

  return value;
};
