export const branchFormDefaultValues = {
  address: "",
  city: "",
  divisionId: "",
  email: "",
  logo: undefined,
  name: "",
  phoneNumber: "",
  postalCode: "",
  quoteCover: undefined,
  state: "",
};

export const divisionFormDefaultValues = {
  id: "",
  divisionName: "",
};
