import { graphql } from "@/lib/graphql";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const DeleteFormTemplate = graphql(`
  mutation DeleteFormTemplate($deleteFormTemplateId: ID!) {
    deleteFormTemplate(id: $deleteFormTemplateId) {
      message
      success
      code
    }
  }
`);

export const useDeleteFormGql = () => {
  const { graphqlRequest } = useAuthContext();
  return useMutation({
    mutationFn: async (deleteFormTemplateId: string) =>
      graphqlRequest(DeleteFormTemplate, { deleteFormTemplateId }),
  });
};
