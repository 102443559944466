import { z } from "zod";

export const OptionSchema = z.object({
  id: z.string(),
  value: z.string(),
});

export const ColorOptionSchema = z.object({
  name: z.string(),
  code: z.string(),
  id: z.string(),
});

export const LocationSchema = z
  .object(
    {
      lat: z.number(),
      lng: z.number(),
      name: z.string(),
    },
    { message: "Selected location required" }
  )
  .optional();

export const CreateJobFormSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  number: z.string().nullable(),
  status: OptionSchema.nullable().optional(),
  location: LocationSchema,
  startDate: z.string().min(1, { message: "Start Date is required" }),
  endDate: z.string().min(1, { message: "End Date is required" }),
  branchId: OptionSchema.nullable().optional(),
  divisionId: OptionSchema.refine(
    ({ id, value }) => {
      return Boolean(id.length && value.length);
    },
    { message: "Division is required" }
  ),
  projectOwner: OptionSchema.nullable().optional(),
  salesPerson: OptionSchema.nullable().optional(),
  notes: z.string().nullable().optional(),
  color: ColorOptionSchema.nullable().optional(),
  customer: z.string().optional(),
  customerContact: z.string().optional(),
  customerEmail: z.string().optional(),
  customerPhone: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (value && value.length < 10) {
          return false;
        }
        return true;
      },
      { message: "Phone must be at least 10 characters" }
    )
    .refine(
      (value) => {
        if (value && value.length > 10) {
          return false;
        }
        return true;
      },
      { message: "Phone number is too long" }
    ),
});

export type JobFormSchema = z.infer<typeof CreateJobFormSchema>;

export const DEFAULT_OPTION = {
  id: "",
  value: "",
};

export const SLATE_HEX = "#8098A3";

export const DEFAULT_COLOR_OPTION = {
  name: "Slate",
  code: SLATE_HEX,
  id: "",
};

export const DEFAULT_VALUES = {
  name: "",
  number: "",
  status: DEFAULT_OPTION,
  location: undefined,
  startDate: "",
  endDate: "",
  branchId: DEFAULT_OPTION,
  divisionId: DEFAULT_OPTION,
  projectOwner: DEFAULT_OPTION,
  salesPerson: DEFAULT_OPTION,
  notes: "",
  color: DEFAULT_COLOR_OPTION,
  customer: "",
  customerContact: "",
  customerEmail: "",
  customerPhone: "",
};

export const STATUS_OPTIONS = [
  {
    id: "scheduled",
    value: "Scheduled",
  },
  {
    id: "opportunity",
    value: "Opportunity",
  },
  {
    id: "ongoing",
    value: "Ongoing",
  },
  {
    id: "complete",
    value: "Complete",
  },
];

export const jobFormToQueryMapper = (job: JobFormSchema) => {
  return {
    name: job.name,
    number: job.number,
    status: job.status?.id ?? null,
    address: job.location,
    branchId:
      job.branchId?.id && job.branchId.id.length ? job.branchId.id : null,
    divisionId:
      job.divisionId.id && job.divisionId.id.length ? job.divisionId.id : null,
    projectOwner:
      job.projectOwner && job.projectOwner.id ? [job.projectOwner.id] : null,
    salesPerson:
      job.salesPerson && job.salesPerson.id ? [job.salesPerson.id] : null,
    notes: job.notes,
    customer: {
      ...(job.customer && { name: job.customer }),
      ...(job.customerEmail && { email: job.customerEmail }),
      ...(job.customerEmail && { email: job.customerEmail }),
      ...(job.customerPhone && { phone: job.customerPhone }),
      ...(job.customerContact && { contactName: job.customerContact }),
    },
    startDate: job.startDate,
    endDate: job.endDate,
    // ?? doesn't work here because if it's an empty string it validates as true
    colorId: job.color?.id ? job.color.id : null,
  };
};

export const jobFormToMutateMapper = (job: JobFormSchema, id: string) => {
  return {
    name: job.name,
    number: job.number,
    status: job.status?.id ?? null,
    address: job.location,
    branchId:
      job.branchId?.id && job.branchId.id.length ? job.branchId.id : null,
    divisionId:
      job.divisionId.id && job.divisionId.id.length ? job.divisionId.id : null,
    projectOwner:
      job.projectOwner && job.projectOwner.id ? [job.projectOwner.id] : null,
    salesPerson:
      job.salesPerson && job.salesPerson.id ? [job.salesPerson.id] : null,
    notes: job.notes,
    customer: {
      ...(job.customer && { name: job.customer }),
      ...(job.customerEmail && { email: job.customerEmail }),
      ...(job.customerEmail && { email: job.customerEmail }),
      ...(job.customerPhone && { phone: job.customerPhone }),
      ...(job.customerContact && { contactName: job.customerContact }),
    },
    id,
    // ?? doesn't work here because if it's an empty string it validates as true
    colorId: job.color?.id ? job.color.id : null,
  };
};
