/* eslint-disable @typescript-eslint/no-explicit-any */

import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";
import { RowField } from "@/types/forms/formEngine";
import {
  evaluateExpression,
  getTitleClassesByAttributes,
  getValueClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";

export interface LocationFieldProps {
  fieldItem: RowField;
  value?: any;
}

export const LocationField: FC<LocationFieldProps> = ({ fieldItem, value }) => {
  const title = fieldItem?.text
    ? fieldItem.text
    : transformTitleByFieldItem(fieldItem);

  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);

  const parseValue = (value: string) => {
    if (!value) return;

    const splittedValue = (value as string).split(",");

    if (
      splittedValue.length >= 3 &&
      Number(splittedValue[0]) &&
      Number(splittedValue[1])
    ) {
      splittedValue.splice(0, 2);
      const name = splittedValue.join(",");

      return name;
    }

    return value;
  };

  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {title && (
        <h4
          className={classNames(
            "text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {title}
        </h4>
      )}

      <p
        className={classNames(
          "overflow-clip text-ellipsis text-base",
          getValueClassesByAttributes(fieldItem?.attributes)
        )}
      >
        {parseValue(value)}
      </p>
    </div>
  );
};
