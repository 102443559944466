import { graphql } from "@/lib/graphql";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";
import { CreateFormTemplateMutationVariables } from "@/lib/graphql/graphql";

const CreateFormTemplate = graphql(`
  mutation CreateFormTemplate($input: GqlFormTemplateInput!) {
    createFormTemplate(input: $input) {
      message
      success
    }
  }
`);

export const useCreateFormGql = () => {
  const { graphqlRequest } = useAuthContext();
  return useMutation({
    mutationFn: async (
      createFormTemplateMutationVariables: CreateFormTemplateMutationVariables
    ) =>
      graphqlRequest(CreateFormTemplate, createFormTemplateMutationVariables),
  });
};
